<template>
  <div ref="pile" class="line-chart"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'PileChart',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    list_x: {
      // immediate: true,
      handler(newV, oldV) {
        this.init()
      }
    }
  },
  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.myChart = echarts.init(this.$refs.pile)
      this.myChart.setOption({
        title: {
          text: this.title,
          // subtext: 'Fake Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '人民调解情况',
            type: 'pie',
            radius: '50%',
            data: this.data,
            // label: {
            //   position: 'inner',
            //   fontSize: 14
            // },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.line-chart {
  width: 100%;
  height: 300px;
}
</style>
