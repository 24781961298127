<template>
  <el-card class="activity">
    <div class="title">{{ cardTitle }}</div>
    <div class="content">
      <el-table :data="list" style="width: 100%; height: 220px; overflow-y: scroll">
        <el-table-column type="index" label="序号" width="width" align="center"></el-table-column>
        <el-table-column prop="file_name" label="标题" width="width" align="center">
          <template slot-scope="{ row }">
            <div class="small-size title-ellipsis">
              <el-tooltip placement="top-start">
                <div style="max-width: 300px" slot="content">{{ row['mediate_content'] ?? '-' }}</div>
                <el-link
                  :underline="false"
                  @click="
                    $router.push({
                      name: 'MediateCaseDetail',
                      params: { id: row['id'] || undefined }
                    })
                  "
                >
                  {{ row['mediate_content'] ?? '-' }}
                </el-link>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'card-title-list',

  props: {
    cardTitle: {
      type: String
    },
    isMediate: {
      type: Boolean,
      default: false
    },
    list: []
  }
}
</script>

<style scoped lang="scss">
.activity {
  box-shadow: none;
  .content /deep/ {
    .el-table {
      &::before {
        display: none !important;
      }
      &::-webkit-scrollbar {
        width: 0px;
        background-color: #ccc;
      }
      .el-table__cell {
        padding: 6px 0;
      }
    }
  }
}
</style>
