<template>
  <div class="wrapper">
    <admin-title title="筛选"></admin-title>
    <el-form :inline="true" ref="form" :model="chartsData" label-width="">
      <el-form-item label="日期">
        <el-date-picker
          ref="pick"
          style="width: 400px; margin-right: 20px"
          v-model="chartsData.timeSlot"
          size="small"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          value-format="yyyy-MM"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="委员会">
        <SelectDistrict v-model="chartsData.search_district_id"></SelectDistrict>
      </el-form-item>
      <el-form-item label="">
        <el-button @click="search" type="primary" size="small">搜索</el-button>
      </el-form-item>
    </el-form>
    <admin-title title="业务监管"></admin-title>
    <!-- 统计 -->
    <el-card style="box-shadow: none; margin: 20px 0">
      <!-- <div slot="header">
        <span style="margin-right: 10px">委员会</span><SelectDistrict></SelectDistrict>
        <el-button style="margin-left: 20px" @click="search" type="primary" size="small">搜索</el-button>
      </div> -->
      <div class="count-box">
        <div class="item" @click="goMediate">
          <div class="num">
            <span class="total">{{ count_stat.mediate_case_total || 0 }}</span>
            <span class="today">今日+{{ count_stat.mediate_case_today_total || 0 }}</span>
          </div>
          <div class="title">案件数量</div>
        </div>
        <div class="item" @click="goDispute">
          <div class="num">
            <span class="total">{{ count_stat.dispute_total || 0 }}</span>
            <span class="today">今日+{{ count_stat.dispute_today_total || 0 }}</span>
          </div>
          <div class="title">纠纷排查数量</div>
        </div>
        <div class="item" @click="goCustomer">
          <div class="num">
            <span class="total">{{ count_stat.user_total || 0 }}</span>
            <span class="today">今日+{{ count_stat.user_today_total || 0 }}</span>
          </div>
          <div class="title">群众端用户数量</div>
        </div>
        <div class="item" @click="goLawPeople">
          <div class="num">
            <span class="total">{{ count_stat.law_people_total || 0 }}</span>
            <span class="today">今日+{{ count_stat.law_people_today_total || 0 }}</span>
          </div>
          <div class="title">司法端用户数量</div>
        </div>
      </div>
    </el-card>

    <!-- <div class="line"></div> -->
    <admin-title title="数据分析"></admin-title>
    <!-- 图表 -->
    <el-card style="box-shadow: none; margin: 20px 0">
      <!-- <div slot="header">
        <el-date-picker
          ref="pick"
          style="width: 400px; margin-right: 20px"
          v-model="chartsData.timeSlot"
          size="small"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          value-format="yyyy-MM"
        >
        </el-date-picker>
        <span style="margin-right: 10px">委员会</span><SelectDistrict></SelectDistrict>
        <el-button style="margin-left: 20px" @click="search" type="primary" size="small">搜索</el-button>
      </div> -->
      <el-row style="margin-bottom: 10px" :gutter="10">
        <el-col :span="12">
          <el-card style="box-shadow: none"
            ><LineChart title="案件数量" v-if="caseInfo.list_x.length > 0" :list_x="caseInfo.list_x" :list_y="caseInfo.list_y"
          /></el-card>
        </el-col>
        <el-col :span="12">
          <el-card style="box-shadow: none"
            ><BarChart
              title="排查登记数量"
              v-if="disputeInfo.list_x.length > 0"
              :list_x="disputeInfo.list_x"
              :list_y="disputeInfo.list_y"
          /></el-card>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-card style="box-shadow: none"
            ><LineChart
              title="调解员数量"
              v-if="lawPeopleInfo.list_x.length > 0"
              :list_x="lawPeopleInfo.list_x"
              :list_y="lawPeopleInfo.list_y"
          /></el-card>
        </el-col>
        <el-col :span="12">
          <el-card style="box-shadow: none"
            ><PileChart title="人民调解情况" v-if="pileCount.mediate_done_count !== undefined" :data="pile_data"
          /></el-card>
        </el-col>
      </el-row>
    </el-card>
    <!-- <div class="line"></div> -->
    <admin-title title="超期列表"></admin-title>
    <div class="timeout">
      <CardTitleListTimeout card-title="超期案件" :list="mediateTimeOutList" />
    </div>
    <admin-title title="最新列表"></admin-title>
    <!-- 列表 -->
    <div class="list">
      <el-row :gutter="10">
        <el-col :span="8">
          <CardTitleList isMediate card-title="最新案件" :list="mediateCaseList" />
        </el-col>
        <el-col :span="8">
          <CardTitleList card-title="最新公告" :list="noticeList" />
        </el-col>
        <el-col :span="8">
          <CardTitleList card-title="用户咨询" :list="consultList" />
        </el-col>
      </el-row>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {
  indexStatAPI,
  latestMediateCaseListAPI,
  latestConsultListAPI,
  latestNoticeListAPI,
  getCaseNumAPI,
  getLawPeopleNumAPI,
  getDisputeNumAPI,
  getPieNumAPI,
  getMediateTimeOutListAPI
} from './api'
import CardTitleList from '@/views/home/components/card-title-list.vue'
import CardTitleListTimeout from '@/views/home/components/card-title-list-timeout.vue'
import LineChart from './charts/line-chart.vue'
import BarChart from './charts/bar-chart.vue'
import PileChart from './charts/pile-chart.vue'
import SelectDistrict from '@/views/components/select-district.vue'
import { mapGetters } from 'vuex'
import { set } from 'vue'
export default {
  name: 'Index',

  components: { CardTitleList, LineChart, BarChart, PileChart, SelectDistrict, CardTitleListTimeout },
  data() {
    return {
      chartsData: {
        timeSlot: [],
        search_district_id: ''
      },
      count_stat: {}, //统计
      mediateCaseList: [],
      noticeList: [],
      consultList: [],
      caseInfo: {
        list_x: [],
        list_y: []
      }, //半年案件数量
      lawPeopleInfo: {
        list_x: [],
        list_y: []
      }, //半年调解员数量
      disputeInfo: {
        list_x: [],
        list_y: []
      }, //半年纠察登记数量
      pileCount: {},
      mediateTimeOutList: [] //案件超期列表
    }
  },

  mounted() {
    this.$bus.$emit('loginchat')

    this.defaultTime()

    this.indexStat()
    latestNoticeListAPI().then(res => {
      this.noticeList = res.data
    })
    latestConsultListAPI().then(res => {
      this.consultList = res.data || []
    })
    latestMediateCaseListAPI().then(res => {
      this.mediateCaseList = res.data || []
    })
  },
  computed: {
    ...mapGetters(['getResultRoutes']),
    date_rule() {
      const { timeSlot } = this.chartsData
      let flag = true
      if (timeSlot && timeSlot.length > 0) {
        const year_start = timeSlot[0].split('-')[0] - 0
        const year_end = timeSlot[1].split('-')[0] - 0
        const month_start = timeSlot[0].split('-')[1] - 0
        const month_end = timeSlot[1].split('-')[1] - 0
        if (year_start != year_end) {
          const res = 13 - month_start + month_end
          if (res > 12) {
            flag = false
          }
        }
      }

      return flag
    },
    pile_data() {
      const { pileCount } = this
      return [
        { value: pileCount.mediate_done_count, name: `调解成功` },
        { value: pileCount.mediate_fail_count, name: '调解失败' },
        { value: pileCount.mediate_zss_count, name: '转诉讼' }
      ]
    }
  },

  methods: {
    // 案件数量
    goMediate() {
      const is_allow = this.getResultRoutes.some(item => item.name == 'MediateList')
      if (!is_allow) return this.$message.warning('您没有权限')
      const { timeSlot, search_district_id } = this.chartsData
      let query = {}
      if (search_district_id) query.search_district_id = search_district_id
      if (timeSlot && timeSlot.length > 0) {
        query.start_month = timeSlot[0]
        query.end_month = timeSlot[1]
      }
      this.$router.push({ name: 'MediateList', query })
    },
    // 纠纷排查数量
    goDispute() {
      const is_allow = this.getResultRoutes.some(item => item.name == 'DisputeCheckList')
      if (!is_allow) return this.$message.warning('您没有权限')
      this.$router.push({ name: 'DisputeCheckList' })
    },
    // 群众端用户数量
    goCustomer() {
      const is_allow = this.getResultRoutes.some(item => item.name == 'CustomerList')
      if (!is_allow) return this.$message.warning('您没有权限')
      this.$router.push({ name: 'CustomerList' })
    },
    // 司法端用户数量
    goLawPeople() {
      const is_allow = this.getResultRoutes.some(item => item.name == 'LawPeopleList')
      if (!is_allow) return this.$message.warning('您没有权限')
      this.$router.push({ name: 'LawPeopleList' })
    },
    async indexStat() {
      const { timeSlot, search_district_id } = this.chartsData
      let params = { search_district_id }
      if (timeSlot && timeSlot.length > 0) {
        params.start_month = timeSlot[0]
        params.end_month = timeSlot[1]
      }
      this.count_stat = await indexStatAPI(params)
    },
    // 案件数量图
    async getCaseNum() {
      const { timeSlot, search_district_id } = this.chartsData
      let params = { search_district_id }
      if (timeSlot && timeSlot.length > 0) {
        params.start_month = timeSlot[0]
        params.end_month = timeSlot[1]
      }
      const res = await getCaseNumAPI(params)
      this.caseInfo.list_x = res.map(item => item.label)
      this.caseInfo.list_y = res.map(item => item.value)
    },
    // 调解员数量图
    async getLawPeopleNum() {
      const { timeSlot, search_district_id } = this.chartsData
      let params = { search_district_id }
      if (timeSlot && timeSlot.length > 0) {
        params.start_month = timeSlot[0]
        params.end_month = timeSlot[1]
      }
      const res = await getLawPeopleNumAPI(params)
      this.lawPeopleInfo.list_x = res.map(item => item.label)
      this.lawPeopleInfo.list_y = res.map(item => item.value)
    },
    // 纠察登记数量图
    async getDisputeNum() {
      const { timeSlot, search_district_id } = this.chartsData
      let params = { search_district_id }
      if (timeSlot && timeSlot.length > 0) {
        params.start_month = timeSlot[0]
        params.end_month = timeSlot[1]
      }
      const res = await getDisputeNumAPI(params)
      this.disputeInfo.list_x = res.map(item => item.label)
      this.disputeInfo.list_y = res.map(item => item.value)
    },
    // 搜索
    search() {
      if (!this.chartsData.timeSlot) {
        return this.$alert('请选择月份！', '提示', {
          confirmButtonText: '确定',
          callback: action => {},
          type: 'warning'
        })
      }
      if (!this.date_rule) {
        return this.$alert('搜索时间段不能超过12个月！', '提示', {
          confirmButtonText: '确定',
          callback: action => {},
          type: 'warning'
        })
      }
      this.indexStat()
      this.getCaseNum()
      this.getLawPeopleNum()
      this.getDisputeNum()
      this.getPieNum()
      this.getMediateTimeOutList()
    },
    // 默认时间
    defaultTime() {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 5)

      const start_y = start.getFullYear()
      let start_m = start.getMonth() + 1
      start_m = start_m > 9 ? start_m : '0' + start_m

      const end_y = end.getFullYear()
      let end_m = end.getMonth() + 1
      end_m = end_m > 9 ? end_m : '0' + end_m

      this.chartsData.timeSlot = [`${start_y}-${start_m}`, `${end_y}-${end_m}`]
      this.getCaseNum()
      this.getLawPeopleNum()
      this.getDisputeNum()
      this.getPieNum()
      this.getMediateTimeOutList()
    },
    // 饼图
    async getPieNum() {
      const { timeSlot, search_district_id } = this.chartsData
      let params = { search_district_id }
      if (timeSlot && timeSlot.length > 0) {
        params.start_month = timeSlot[0]
        params.end_month = timeSlot[1]
      }
      this.pileCount = await getPieNumAPI(params)
    },

    // 案件超期列表
    async getMediateTimeOutList() {
      const { timeSlot, search_district_id } = this.chartsData
      let params = { search_district_id }
      if (timeSlot && timeSlot.length > 0) {
        params.start_month = timeSlot[0]
        params.end_month = timeSlot[1]
      }
      this.mediateTimeOutList = await getMediateTimeOutListAPI(params)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .count-box {
    display: flex;
    justify-content: space-between;
    .item {
      cursor: pointer;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 35px 0;
      border-radius: 10px;
      &:hover {
        background-color: #f0f2f5;
      }
      .num {
        position: relative;
        .total {
          font-size: 18px;
          font-weight: 600;
        }
        .today {
          position: absolute;
          top: 0;
          right: 0;
          transform: translateX(100%) translateY(-100%);
          white-space: nowrap;
          background-color: #0571f2;
          color: #fff;
          padding: 0 10px;
          border-radius: 20px;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        margin-top: 5px;
      }
    }
  }
  .line {
    margin: 20px 0;
    height: 10px;
    background-color: #f0f2f5;
  }
  .list {
    // background-color: #f0f2f5;
    margin-top: 20px;
  }
  .tips {
    font-size: 12px;
    margin-left: 20px;
  }
  .timeout {
    margin-bottom: 20px;
  }
}
</style>
