<template>
  <div ref="line" class="line-chart"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'LineChart',
  props: {
    title: {
      type: String,
      default: ''
    },
    list_x: {
      type: Array,
      default: () => []
    },
    list_y: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    list_x: {
      // immediate: true,
      handler(newV, oldV) {
        this.init()
      }
    }
  },
  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.myChart = echarts.init(this.$refs.line)
      this.myChart.setOption({
        title: [
          {
            text: this.title,
            left: 'center',
            top: '25px',
            textStyle: { color: '#1E6BEB', fontSize: '16px' }
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '25%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.list_x,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: { interval: 0, rotate: 30 }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: { show: true },
            axisTick: { show: true }
          }
        ],

        series: [
          {
            name: '数量',
            type: 'line',
            data: this.list_y,
            smooth: true
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.line-chart {
  width: 100%;
  height: 300px;
}
</style>
