import { http } from '@/http/axios.js'

export function indexStatAPI(params) {
  return http({
    url: `/company/index/stat`,
    method: 'get',
    params
  })
}

export function latestMediateCaseListAPI() {
  return http({
    url: `/company/mediate-case/list`,
    method: 'get'
  })
}

export function latestNoticeListAPI() {
  return http({
    url: `/company/notice/list`,
    method: 'get'
  })
}

export function latestConsultListAPI() {
  return http({
    url: `/company/consult/list`,
    method: 'get'
  })
}
// 半年案件数量
export function getCaseNumAPI(params) {
  return http({
    url: '/company/index/statHalfYearCase',
    method: 'get',
    params
  })
}
// 半年调解员数量
export function getLawPeopleNumAPI(params) {
  return http({
    url: '/company/index/statHalfYearLawPeople',
    method: 'get',
    params
  })
}
// 半年纠察登记数量
export function getDisputeNumAPI(params) {
  return http({
    url: '/company/index/statHalfYearDispute',
    method: 'get',
    params
  })
}
// 统计
export function getStaticAPI(id) {
  return http({
    url: '/company/address-book/stat',
    method: 'get',
    params: { id }
  })
}
// 饼状图
export function getPieNumAPI(params) {
  return http({
    url: '/company/index/statCaseRatio',
    method: 'get',
    params
  })
}
// 案件超期列表
export function getMediateTimeOutListAPI(params) {
  return http({
    url: '/company/index/getTimeoutMediateCaseList',
    method: 'get',
    params
  })
}
